<template>
  <div class="home">
    <div class="border border border-primary rounded-top mx-auto card-header ml-2 mr-2" >
      Retailer Management
    </div>
    <ul class="nav nav-tabs mt-2">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="{active : tabid==0}" href="#" v-on:click="showDealer">Distributor</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="{active : tabid==1}"  href="#" v-on:click="showRetailer">Retailer</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="{active : tabid==2}"  href="#" v-on:click="showHotspot">Hotspot</a>
      </li>
    </ul>
    <div v-show="tabid==0">
      <DealersMgmtComp />
    </div>
    <div v-show="tabid==1">
      <RetailerMgmtComp />
    </div>
    <div v-show="tabid==2">
      <HotspotMgmtComp />
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import DealersMgmtComp from '@/components/DealersMgmt.vue'
import RetailerMgmtComp from '@/components/RetailerMgmt.vue'
import HotspotMgmtComp from '@/components/HotspotMgmt.vue'

export default {
  name: 'RetailerManagement',
  components: {
    DealersMgmtComp,
    RetailerMgmtComp,
    HotspotMgmtComp
  },
  data: function(){
        return {
            tabid:0
        }
  },
  methods:{
      showDealer:function(){
          this.tabid=0;
      },
      showRetailer:function(){
          this.tabid=1;
      },
      showHotspot:function(){
          this.tabid=2;
      }
  }
}
</script>

