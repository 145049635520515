<template>
    <div class="card text-center">
        <div class="card-body">
            <div class="form-group row">
                <h4> Browse Invoice</h4>
            </div>
            
                <div class="form-row">
                    <label class="col-2 col-form-label ">Distributor</label>
                    <div class="col-4">
                        <select class="form-control" v-model="distributor" @change="listRetailers">
                            <option v-for="distributor in distributors" v-bind:value="distributor.id" v-bind:key="distributor.id">{{distributor.name}}</option>
                        </select>
                    </div>
                    <label class="col-1 col-form-label ">Retailer</label>
                    <div class="col-3">
                        <select class="form-control" v-model="retailer">
                            <option v-for="retailer in retailers" v-bind:value="retailer.id" v-bind:key="retailer.id">{{retailer.name}}</option>
                        </select>
                    </div>
                    <div class="col-2">
                        <button class="btn btn-outline-primary" v-on:click="listInvoices">Get</button>
                    </div>
                </div>
            
            <table class="table mt-2">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Invoice Date</th>
                        <th>Invoice Amount</th>
                        <th>Boxes</th>
                        <th>Credit Note</th>
                        <th>GR</th>
                        <th>Payment</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invoice,idx in invoices" v-bind:key="idx">
                        <th>{{invoice.product}}</th>
                        <th>{{invoice.invDate}}</th>
                        <th>{{invoice.invAmount}}</th>
                        <th>{{invoice.qty}}</th>
                        <th>{{invoice.crnote}}</th>
                        <th>{{invoice.gr}}</th>
                        <th>{{invoice.payment}}</th>
                        <th>{{invoice.invAmount - invoice.payment}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name:"listInvoiceComp",
    created(){
        this.baseurl = this.$_appBaseUrl;
        this.listDistributors();
    },
    data: function(){
        return {
            distributor:"",
            distributors:[],
            retailer:"",
            retailers:[],
            invdetails : {"custID":"","distributor":"","retailer":"","product":"","invoiceDate":"","invoiceAmount":0,"productQty":0,"crNote":"","gr":"","payment":""},
            invoices:[],
            baseurl:""
        }
    },
    methods:{
        listRetailers:function(){
            this.retailers=[];
            var url =this.baseurl + "/distributor/" + this.distributor + "/retailer";
            axios.get(url).then(response => {
                this.retailers = response.data;
            });
        },
        listInvoices:function(){
            this.invoices=[];
            if(this.retailer!="" && this.distributor!=""){
                var url = this.baseurl + "/invoice/" + this.retailer;
                axios.get(url).then(response=>{
                    this.invoices = response.data;
                });
            }
            
        },
        listDistributors:function(){
            var url = this.baseurl + '/distributor';
            axios.get(url).then(response => {
                this.distributors = response.data;
            });
        }
    }
}
</script>
