import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.config.productionTip = false;
Vue.prototype.$_appBaseUrl ="http://sales.dhanyavad.co.in/api";
//Vue.prototype.$_appBaseUrl ="http://localhost:3000";
Vue.use(axios);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
