<template>
    <div class="card " >
        <div class="card-body">
            <div class="row"> 
                <div class=" p-4">
                    <button type="button" class="btn btn-outline-primary btn-sm mt-2 ml-2" v-on:click="refresh">
                        Refresh 
                    </button>
                </div>
            </div>
            <form>
                <div class="form-group row">
                    <label class="col-2 col-form-label ">Distributor</label>
                    <div class="col-4">
                        <select class="form-control" v-model="nDistributor">
                            <option v-for="distributor in distributors" v-bind:value="distributor.id" v-bind:key="distributor.id">{{distributor.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-2 col-form-label ">Name</label>
                    <div class="col-4">
                        <input type="text" class="form-control form-control-sm" v-model="nRetName">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-2 col-form-label ">Person</label>
                    <div class="col-4">
                        <input type="text" class="form-control form-control-sm" v-model="nPerson">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-2 col-form-label ">Phoneno</label>
                    <div class="col-4">
                        <input type="text" class="form-control form-control-sm" v-model="nPhoneno">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-2 col-form-label ">Address</label>
                    <div class="col-4">
                        <textarea class="form-control form-control-sm" v-model="naddress" rows="3"> </textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <button type="button" class="btn btn-primary" v-on:click="createRetailer" data-dismiss="modal">Save changes</button>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name:"RetailerMgmtComp",
    created (){
        this.refresh();
    },
    data: function(){
        return {
            distributors:[],
            nDistributor:"",
            nRetName:"",
            nPerson:"",
            nPhoneno:"",
            naddress:"",
            baseurl : ""
        }
    },
    methods:{
        createRetailer:function(){
            var data = {"name":this.nRetName,"person":this.nPerson,"phoneno":this.nPhoneno,"distributorid":this.nDistributor,"address":this.naddress};
            var url= this.baseurl + "/retailer";
            axios.post(url,data).then(response => {
                console.log(response);
            });
            this.nDistributor="";
            this.nRetName="";
            this.nPerson="";
            this.nPhoneno="";
            this.naddress=""
        },
        refresh:function(){
            this.baseurl = this.$_appBaseUrl;
            var url = this.baseurl + "/distributor";
            axios.get(url).then(response => {
                this.distributors = response.data;
            });
        }
    }
}
</script>