<template>
    <div>
        <div class="card text-center">
            <div class="card-body">
                <form>
                    <div class="form-group row">
                        <h4> New Invoice</h4>
                    </div>

                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Distributor</label>
                        <div class="col-sm-10">
                            <select class="form-control" v-model="distributor" @change="listRetailers">
                                <option v-for="distributor in distributors" v-bind:value="distributor.id" v-bind:key="distributor.id">{{distributor.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Retailer</label>
                        <div class="col-sm-10">
                            <select class="form-control" v-model="retailer">
                                <option v-for="retailer in retailers" v-bind:value="retailer.id" v-bind:key="retailer.id">{{retailer.name}}</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Invoice Date</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="Date" v-model="invoiceDate">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Billing Amount</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="Number" v-model="invoiceAmount">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Quantity</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="Number" v-model="productQty">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Credit Note</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="text" v-model="crNote">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">GR</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="text" v-model="gr">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Due Date ({{creditDays}} days)</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="date" v-model="payment">
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer text-muted">
                <button class="btn btn-outline-primary" v-on:click="createInvoice">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name:"NewInvoice",
    props: {
        planets: {}
    },
    data: function(){
        return {
            distributor:"",
            distributors:[],
            retailer:"",
            retailers:[],
            product:"",
            products:[],
            invoiceDate:"",
            invoiceAmount:0,
            productQty:0,
            crNote:"",
            gr:"",
            payment:"2020-11-11",
            baseurl : "",
            creditDays:15
        }
    },
    created:function(){
        this.baseurl = this.$_appBaseUrl;
        //this.distributors=[{"name":"distributor1","id":"1","code":""},{"name":"distributor2","id":"2","code":""},{"name":"distributor3","id":"3","code":""}];
        var url = this.baseurl + "/distributor";
        axios.get(url).then(response => {
            this.distributors = response.data;
        });
        
        var today = new Date();
        this.invoiceDate = today.getFullYear() + "-" + ("0" + (today.getMonth()+1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
    },
    watch:{
        invoiceDate:function(){
            var d = new Date(this.invoiceDate) ;
            d.setDate(d.getDate()+  this.creditDays);
            this.payment= d.getFullYear() + "-" + ("0"+ (d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
        }
    },
    methods:{
        listRetailers:function(){
            this.retailers=[];

            var url = this.baseurl + "/distributor/" + this.distributor + "/retailer";
            axios.get(url).then( response => {
                this.retailers = response.data;
            });
            
        },
        createInvoice(){
            var data ={ "distributorID":this.distributor,"retailerID":this.retailer,"productID":this.product,"invDate":this.invoiceDate,"invAmount":this.invoiceAmount,
                        "qty":this.productQty,"crnote":this.crNote,"gr":this.gr,"payment":this.payment};
            var url = this.baseurl + "/invoice";

            axios.post(url,data).then(response => {
                console.log(response);
            });
            this.distributor="";
            this.retailer="";
            this.product="";
            this.invoiceDate="";
            this.invoiceAmount=0;
            this.productQty=0;
            this.crNote="";
            this.gr="";
            this.payment="";
        }
    }
}
</script>