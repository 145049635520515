<template>
  <div class="home">
    <div class="border border border-primary rounded-top mx-auto card-header ml-2 mr-2" >
      Invoice Management
    </div>
    <ul class="nav nav-tabs mt-2">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="{active : tabid==0}" href="#" v-on:click="showNewInvoice">New</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="{active : tabid==1}"  href="#" v-on:click="showListInvoice">List</a>
      </li>
    </ul>
    <div v-show="tabid==0">
      <NewInvoice />
    </div>
    <div v-show="tabid==1">
      <listInvoiceComp />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NewInvoice from '@/components/NewInvoice.vue'
import listInvoiceComp from '@/components/ListInvoice.vue'

export default {
  name: 'InvoiceManagement',
  components: {
    NewInvoice,
    listInvoiceComp
  },
  data: function(){
        return {
            tabid:0
        }
  },
  methods:{
    showNewInvoice:function(){
      this.tabid=0;
    },
    showListInvoice:function(){
      this.tabid=1;
    }
  }
}
</script>
