<template>
    <div class="mt-2">
    <div class="card-deck mt-2">
        <div class="card mt-2">
            <!--<img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-primary text-white">APR</h5>
            <!--<p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>-->
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr><td></td><td>2020</td><td>2021</td></tr>
                    </thead>
                    <tbody>
                        <tr><td>Sales</td><td>10000</td><td>10000</td></tr>
                        <tr><td>Payments</td><td>5000</td><td>5000</td></tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-primary text-white">MAY</h5>
            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
            
            </div>
        </div>
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-primary text-white">JUN</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            
            </div>
        </div>
    </div>
    <div class="card-deck mt-2">
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-info text-white">JUL</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            
            </div>
        </div>
        <div class="card  mt-2">
            <!--<img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-info text-white">AUG</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
            
            </div>
        </div>
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-info text-white">SEP</h5>
            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
            
            </div>
        </div>
    </div>
    <div class="card-deck mt-2">
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-success text-white">OCT</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            
            </div>
        </div>
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-success text-white">NOV</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            
            </div>
        </div>
        <div class="card mt-2">
            <!--<img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-success text-white">DEC</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
            
            </div>
        </div>
    </div>
    <div class="card-deck mt-2">
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-secondary text-white">JAN</h5>
            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
            
            </div>
        </div>
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-secondary text-white">FEB</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            
            </div>
        </div>
        <div class="card mt-2">
            <!--<img  class="card-img-top" alt="..."> -->
            <div class="card-body">
            <h5 class="card-title bg-secondary text-white">MAR</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name:"Y2YAnalysis",
    created(){
        this.baseurl = this.$_appBaseUrl;
        alert(this.baseurl);
    },
    data: function(){
        return {
            baseurl : ""
        }
    }
}
</script>