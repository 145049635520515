<template>
    <div class="home">
        <div class="border border border-primary rounded-top mx-auto card-header ml-2 mr-2" >
         Analysis
        </div>
        <ul class="nav nav-tabs mt-2">
            <li class="nav-item">
                <a class="nav-link" v-bind:class="{active : tabid==0}" href="#" v-on:click="showDealer">Y2Y Analyis</a>
            </li>
            <!--
            <li class="nav-item">
                <a class="nav-link" v-bind:class="{active : tabid==1}"  href="#" v-on:click="showRetailer">Trend</a>
            </li>
            -->
        </ul>
        <div v-show="tabid==0" class="mb-2">
            <Y2YAnalysisComponent />
        </div>
        <!--
        <div v-show="tabid==1">
            Trend analyis
        </div>
        -->
    </div>
</template>

<script>
import Y2YAnalysisComponent from '@/components/Y2YAnalysis.vue';

export default {
    name:"SalesAnalysis",
    
    data: function(){
            return {
                tabid:0
            }
    },
    components:{
        Y2YAnalysisComponent
    },
    methods:{
      showDealer:function(){
          this.tabid=0;
      },
      showRetailer:function(){
          this.tabid=1;
      }
    }
}
</script>