<template>
    <div>
        <div class="row"> 
            <div class=" p-4">
                <button type="button" class="btn btn-outline-primary btn-sm mt-2 " data-toggle="modal" data-target="#newHotspotForm">
                    New 
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm mt-2 ml-2" v-on:click="refresh">
                    Refresh
                </button>
            </div>
            <div class="modal fade" id="newHotspotForm" tabindex="-1" role="dialog"  aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" >New Hotspot</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" v-model="nHotspot">
                                </div>
                                <div class="col-sm-2">
                                    <div :style="{'background-color': ncolor,'color':ncolor}">#FFF</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Color</label>
                                <div class="col-sm-10 d-flex align-content-stretch flex-wrap ">
                                    <div v-for="color in colors" v-bind:key="color" class="border" :style="{'background-color': color,'color':color}" v-on:click="say(color);">#FFF380</div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn  btn-outline-primary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn  btn-outline-primary" v-on:click="createHotspot" data-dismiss="modal">Save</button>
                    </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="addRetailerToHotspotForm" tabindex="-1" role="dialog"  aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title " >Hotspot - {{dlgBoxHotspot.name}}</h5>
                        <span class="border rounded dot m-1" :style="{'background-color': dlgBoxHotspot.category,'color':dlgBoxHotspot.category}"></span>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-form-label p-2 m-2">Select Retailers</label>
                                <select multiple class="form-control p-2 m-2" v-model="dlgBoxRetailers">
                                    <option v-for="retailer in retailers" v-bind:value="retailer.id" v-bind:key="retailer.id">{{retailer.name}}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn  btn-outline-primary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn  btn-outline-primary" v-on:click="addRetailers" data-dismiss="modal">Save</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap ">
            <div v-for="(hotspot,idx) in hotspots" v-bind:key="idx" class="card ml-2 mt-2" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">{{hotspot.name}}</h5>
                    <span class="border rounded dot m-1" :style="{'background-color': hotspot.category,'color':hotspot.category}"></span>
                </div>
                <ul class="list-group list-group-flush">
                    <li v-for="(retailer,idx) in hotspot.retailers" v-bind:key="idx" class="list-group-item">{{retailer.name}}</li>
                </ul>
                <div class="card-footer">
                 <button class="btn btn-outline-primary" v-on:click="showDlgboxManageRetailers(hotspot,idx)">Manage Retailers</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';

export default {
    name:"HotspotMgmtComp",
    data: function(){
        return {
            baseurl:"",
            nHotspot:"",
            ncolor: "#FFFFFF",
            dlgBoxSelectedIndex:0,
            dlgBoxRetailers:[],
            dlgBoxHotspot:{"name":"hotspot1","category":"#2B547E","retailers":[{"name":"Retailer1"}]},
            colors:["#FFFFFF","#2B547E","#4863A0","#6D7B8D","#736F6E","#3B3131","#151B8D","#0000A0","#64E986","#5FFB17","#B1FB17","#FFF380"],
            retailers:[],
            hotspots:[],
        }
    },
    created(){
        this.baseurl = this.$_appBaseUrl;
        this.listHotspots();
        //this.hotspots=[{"name":"hotspot1","category":"#2B547E","retailers":[{"name":"Retailer1"}]},{"name":"hotspot2","category":"#5FFB17"},{"name":"hotspot3","category":"#0000A0"}];
        
        var url = this.baseurl + '/hotspot';
        axios.get(url).then(response => {
            this.hotspots = response.data;
        });

        url = this.baseurl + '/retailer';
        axios.get(url).then(response => {
            this.retailers = response.data;
        });
        //this.retailers=[{"name":"Retailer1","id":1},{"name":"Retailer2","id":2},{"name":"Retailer3","id":3},{"name":"Retailer4","id":4},{"name":"Retailer5","id":5}];
    },
    methods:{
        say:function(color){
            this.ncolor=color;
        },
        createHotspot:function(){
            var url =this.baseurl + "/hotspot" ;
            var data = {"name":"","category":""};
            data.name = this.nHotspot;
            data.category=this.ncolor;
            axios.post(url,data).then(response=>{
                console.log(response)
            });
        },
        refresh:function(){
            this.listHotspots();
        },
        showDlgboxManageRetailers:function(hotspot,idx){
            this.dlgBoxSelectedIndex=idx;
            this.dlgBoxHotspot = hotspot;
            $("#addRetailerToHotspotForm").modal();
        },
        addRetailers:function(){
            var url =this.baseurl + "/hotspot/retailer" ;
            var data = {"hotspotid":this.dlgBoxHotspot.hotspotid,"retailers":this.dlgBoxRetailers};
            axios.post(url,data).then(response => {
                console.log(response);
            });
            this.listHotspots();
        
        },
        listHotspots:function(){
            var url = this.baseurl + '/hotspot';
            axios.get(url).then(response => {
                this.hotspots = response.data;
            });
        }
    }
}
</script>

<style>
    .dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
</style>