<template>
    <div>
        <div class="row"> 
            <div class=" p-4">
                <button type="button" class="btn btn-outline-primary btn-sm mt-2 " data-toggle="modal" data-target="#newDistributorForm">
                    New 
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm mt-2 ml-2" v-on:click="refresh">
                    Refresh
                </button>
            </div>
            <div class="modal fade" id="newDistributorForm" tabindex="-1" role="dialog" aria-labelledby="newDistributorFormTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" >New Distributor</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" v-model="ndistName">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Code</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" v-model="ndistCode">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Address</label>
                                <div class="col-sm-10">
                                    <textarea type="text" class="form-control" v-model="ndistAddress" rows=3>></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" v-on:click="createDistributor" data-dismiss="modal">Save changes</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap ">
            <div v-for="(dist,idx) in distributors" v-bind:key="idx" class="card ml-2 mt-2" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">{{dist.name}}</h5>
                    <p class="card-text">{{dist.address}}</p>
                </div>
                <ul class="list-group list-group-flush">
                    <li v-for="(retailer,idx) in dist.retailers" v-bind:key="idx" class="list-group-item">{{retailer.name}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name:"DealersMgmtComp",
    created (){
        this.refresh();
    },
    data: function(){
        return {
            distributors:[],
            ndistName:"",
            ndistAddress:"",
            ndistCode:"",
            axiosResp:[],
            baseurl : ""
        }
    },
    methods:{
        createDistributor: function(){
            var data = {"name":this.ndistName,"address":this.ndistAddress,"code":this.ndistCode};
            //this.distributors.push(data);
            var url = this.baseurl + "/distributor";
            axios.post(url,data).then(response => {
                console.log(response);
                this.refresh();
            });
            this.ndistName="";
            this.ndistAddress="";
            this.ndistCode="";
            this.retailers=[];
        },
        refresh:function(){
            this.baseurl = this.$_appBaseUrl;
            var url = this.baseurl + "/distributor/retailers";
            axios.get(url).then(response => {
                this.distributors = response.data;
            });
        }

    }
}
</script>
